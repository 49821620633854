@media (min-width: 600px) {
.modalContainer {
  z-index: 1222;
  margin-left: 1vw;
  margin-top: 2vw;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 500px;
}
.input3{
  width: 90%;
  height: 100%;
  border: 2px lightgray solid;
  background-color: white;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.imagee{
  width: 100px;
  height: auto;
  margin: 15px 16px 0px 20px;
}
.imagee > img{
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.search{
  width: 100%;
  border-radius: 5px;
  height: 40px;
  margin: auto;
  font-size: 1vw;
  text-align: center;
  margin-bottom: 2vw;
  margin-top: 1vw;
}
.container-search{
  margin: 10px 80px 0 0px;
  position: relative;
  top: 0;
  width: 70%;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
.button-search{
  position: absolute;
  top: 1.2vw;
  right: 0;
  background: none;
  border: none;
  width: 40px;
  height: auto;
}
.button-search > svg{
  width: 100%;
  height: 100%;
}
.cross{
  position: absolute;
  top: 20px;
  right: 20px;
  fill: #5B40D7;
  cursor: pointer;
}
}
@media (max-width: 599px) {
  .modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 11;
  }
  .input3{
    width: 90%;
    height: 90%;
    border-radius: 20px;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  .imagee{
    width: 100px;
    height: auto;
    margin: 15px 16px 0px 20px;
  }
  .imagee > img{
    width: 100%;
    height: 100%;
  }
  .search{
    width: 100%;
    border-radius: 25px;
    height: 40px;
    margin: auto;
    text-align: center;
    font-size: 15px;
  }
  .container-search{
    width: 70%;
    margin: 15px 0 15px 0px;
    position: relative;
    top: 0;
  }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }
  .button-search{
    position: absolute;
    top: 5px;
    right: 0;
    background: none;
    border: none;
    width: 40px;
    height: auto;
  }
  .button-search > svg{
    width: 100%;
    height: 100%;
  }
  .cross{
    position: absolute;
    top: 20px;
    right: 20px;
    fill: #5B40D7;
  }
}
