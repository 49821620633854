body, html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
  background-color: #fff5ff;
  display: flex;
  flex-direction: column;
  overflow: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

@media only screen and (min-width : 600px) {
  #svgContent {
    #svg {
      // -webkit-filter: drop-shadow( 7px 7px 5px rgba(0, 0, 0, .7))
 } }      // filter: drop-shadow( 7px 7px 5px rgba(0, 0, 0, .7))
  #font-picker {
    margin: auto;
    width: 13vw;
    font-size: .7vw;
    margin-top: 1vw;
    margin-bottom: 2vw; }
  .textarea {
    margin: auto;
    width: 13vw;
    height: 4vw;
    font-size: .7vw;
    margin-top: 1vw;
    resize: none; }
  .inputqty {
    width: 3vw;
    height: 1vw;
    margin-top: 0.5vw;
    margin-bottom: .5vw;
    text-align: center;
    font-size: .7vw; }
  .tiers {
    display: flex;
    p {
      margin: 0;
      padding: 0;
      width: 20%;
      margin-right: 23%;
      text-align: left;
      margin-bottom: .2vw;
      font-size: .6vw;
      &:hover {
        cursor: pointer;
        text-decoration: underline; } } }
  .price {
    width: 8vw;
    margin-top: -15vw;
    background-color: white;
    border: solid 2px lightgray;
    padding: 1vw;
    margin-left: -1.2vw; }

  button {
    cursor: pointer; }
  .App, #root {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    overflow: hidden; }
  .option-right {
    width: 100%;
    position: absolute;
    bottom: 2vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }

  .column-right {
    position: relative;
    min-width: 300px;
    width: 23%;
    height: 100%;
    background-color:  #e7e7e7;
    border-left: solid 2px lightgray;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .params {
      margin: 0 auto 0 auto;
      display: flex;
      flex-direction: row;
      width: 75%;
      background-color: white;
      .param:nth-child(2) {
          border-right: none;
          border-left: none; }
      .param {
        width: 33%;
        border: 3px grey solid;
        height: auto;
        input {
          width: 100%;
          height: 100%; }
        p {
          text-align: center; } }
      h5 {
        font-size: .6vw; }
      .height {
        all: unset;
        border: 2px lightgray solid;
        bo {}
        width: 33%;
        text-align: center;
        input {
          width: 50%;
          height: 1vw;
          font-size: .8vw;
          margin-bottom: 1vw; } }
      .width {
        all: unset;
        border: 2px lightgray solid;
        width: 33%;
        text-align: center;
        input {
          width: 50%;
          height: 1vw;
          font-size: .8vw;
          margin-bottom: 1vw; } }
      .quantity {
        all: unset;
        border: 2px lightgray solid;
        width: 33%;
        text-align: center;
        svg {
          height: 1vw;
          width: 1vw;
          margin: 0;
          margin-left: .5vw;
          margin-right: .5vw;
          &:hover {
            cursor: pointer; } }
        p {
          margin: 0;
          margin-bottom: .5vw;
          font-size: 1vw; } } }
    .final-price {
      margin: 0 auto 3vw auto;
      background-color: white;
      width: calc(75% - 4px);
      border: 2px lightgray solid;
      display: flex;
      justify-content: space-around;
      font-size: 1vw; }
    .add-panier {
      width: 70%;
      height: 3vw;
      font-size: 1vw;
      background-color: #F2A93B;
      color: white;
      border: 3px solid gray;
      border-radius: 25px;
      padding: 10px 20px 9px;
      font-weight: 600;
      margin: 0 auto 30px auto;
      &:hover {
        background-color: #e09626; } }
    .modal {
      width: 90%;
      margin-left: 1vw;
      margin-top: 2vw;
      z-index: 11;
      display: flex;
      .content {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: white;
        border: 2px lightgray solid;
        width: 90%;
        margin: auto;
        min-height: 300px;
        top: 40%;
        transform: translateY(-40%);
        padding-bottom: 2vw;
        svg {
          cursor: pointer; } }
      .cross {
        position: absolute;
        top: 20px;
        right: 20px;
        fill: #5B40D7; }
      .done {
        display: none;
        margin: 2vw auto 20px auto;
        width: 60%;
        height: 2vw;
        border: none;
        background-color: #5B40D7;
        color: #fff;
        font-size: 1vw;
        font-weight: bold;
        border-radius: 25px;
        bottom: 1vw;
        left: 1vw;
        &:hover {
          background-color: #3a288e; } }
      h4 {
        color: #868E96;
        text-align: center;
        font-size: 14px; }
      .label {
        width: 80%;
        margin: 0 auto 0 auto;
        display: flex;
        flex-direction: column;
        input {
          display: none; }
        p {
          text-align: right;
          width: 30vw;
          margin-right: 2vw;
          margin-top: .8vw;
          font-size: 1vw; }
        img {
          width: 4vw;
          height: 4vw;
          margin-left: 35px; }
        label {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 3vw;
          text-align: center;
          &:hover {
            cursor: pointer; }
          img {
            width: 2vw;
            height: 2vw;
            margin-top: .5vw; } } }
      .labelMat {
        width: 80%;
        margin: 0 auto 0 auto;
        display: flex;
        flex-direction: column;
        input {
          display: block; }
        p {
          text-align: right;
          width: 30vw;
          // margin-right: 2vw
          margin-top: .8vw;
          font-size: 1vw; }
        img {
          width: 4vw;
          height: 4vw;
 }          // margin-left: 35px
        label {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 3vw;
          text-align: center;
          align-items: center;
          &:hover {
            cursor: pointer; }
          img {
            width: 2vw;
            height: 2vw;
            margin-right: 25px; } } }
      .input {
        width: 100%;
        min-height: 180px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        input {
          width: 75%;
          border-radius: 5px;
          height: 40px;
          margin: 10px auto;
          font-size: 1vw;
          text-align: center;
          margin-bottom: 1.5vw;
          margin-top: 1vw; }
        .change {
          display: flex;
          margin: auto;
          justify-content: space-around;
          width: 80%;
          margin-top: 6vw; }
        svg {
          width: 15vw;
          height: 15vw; } }
      .css-tlfecz-indicatorContainer {
        svg {
          width: 1vw;
          height: 1vw; } }
      .css-1gtu0rj-indicatorContainer {
        svg {
          width: 1vw;
          height: 1vw; } }
      .add-text {
        width: 5vw;
        margin: auto;
        border: 3px solid #C6B9F1;
        background: #C6B9F1;
        border-radius: 5px;
        height: 1.4vw;
        font-size: .6vw;
        font-weight: bold;
        color: white;
        margin-top: 2vw;
        &:hover {
          background: #8864fe; } }
      .css-1s2u09g-control {
        width: 80%;
        margin: auto; }
      .css-1pahdxg-control {
        margin: auto;
        width: 80%; }
      .css-319lph-ValueContainer {
        height: 40px; }
      .css-7pwich-Input {
        height: 1.5vw; }
      // .modalContainer
      //   width: 200px
      //   height: 350px
      //   position: absolute
      //   top: 50px
      //   left: 50px
      //   .input3
      //     width: 100%
      //     height: 350px
      //     padding-top: 80px
      //     overflow-y: scroll
      //     display: flex
      //     flex-wrap: wrap
      //     align-items: center
      //     justify-content: space-between
      //     div
      //       width: 100px
      //       height: auto
      //       margin: 0 15px 10px 10px
      //     img
      //       width: 100px
      //       height: auto
      //     input
      //       width: 75%
      //       border-radius: 25px
      //       height: 40px
      //       margin: auto
      //       font-size: 1vw
      //       text-align: center
      //     .change
      //       display: flex
      //       margin: auto
      //       justify-content: space-around
      //       width: 80%
      //       margin-top: 6vw
      //     svg
      //       width: 15vw
      //       height: 15vw
      .react-colorful {
        margin: auto;
        width: 75%;
        height: 200px;
        margin-top: 30px; }
      .colorShow {
        width: 30%;
        height: 50px;
        border-radius: 10px;
        border: 5px solid black;
        margin: 50px auto 20px auto; } } }

  .column-extrem {
    min-width: 100px;
    width: 5vw;
    height: 100%;
    background-color: rgb(134, 56, 89);
    border-left: 3px solid rgb(251, 161, 199);
    div {
      width: 100%;
      // min-height: 30px
      height: 7vw;
      border-bottom: solid rgb(251, 161, 199) 2px;
      button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none; } }
    svg {
      fill: white; }
    p, h5 {
      color: white; }
    label {
      font-size: 20px;
      cursor: pointer;
      position: relative;
      width: 100%;
      height: 100%;
      svg {
        text-align: center;
        margin: 0 auto 0 auto;
        padding-top: 1vw;
        width: 2vw;
        height: 2vw;
        display: block; }
      p {
        margin-top: 1vw;
        text-align: center;
        font-weight: bold;
        font-size: .7vw; } }
    input {
      opacity: 0;
      top: 0;
      position: absolute; }
    .form {
      font-size: .7vw;
      h5 {
        font-size: .7vw;
        margin: 0 auto 2px auto; }
      p {
        font-size: .7vw;
        margin: 0 auto 0 auto;
        text-align: center; }
      img {
        margin: 0 auto 2px auto;
        width: 3vw;
        height: 3vw; } }
    .text {
      width: 100%;
      h5 {
        font-size: .7vw;
        margin: 0 auto 0 auto; }
      svg {
        margin: 5px auto 0 auto;
        width: 2vw;
        height: 2vw; } }
    .background {
      opacity: 1;
      position: initial;
      width: 100%;
      h5 {
        font-size: .7vw;
        margin: 0 auto 0 auto; }
      .myColor {
        width: 2vw;
        height: 2vw;
        border: 1px lightgray solid;
        border-radius: 100%;
        -webkit-box-shadow: 5px 5px 10px -2px rgba(0,0,0,0.5);
        box-shadow: 5px 5px 10px -2px rgba(0,0,0,0.5);
        margin: 5px auto 0 auto; } }
    .image {
      width: 100%;
      h5 {
        font-size: .7vw;
        margin: 0 auto 0 auto; }
      svg {
        margin: 5px auto 0 auto;
        width: 2vw;
        height: 2vw; } }
    .materiaux {
      position: initial;
      width: 100%;
      h5 {
        font-size: .7vw;
        margin: 0 auto 0 auto; }
      img {
        margin: 0 auto 2px auto;
        width: 2vw;
        height: 2vw; }
      p {
        font-size: .7vw;
        margin: 0 auto 0 auto;
        text-align: center; } } }
  .editor {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72%;
    height: 100%;
    .widthSize {
      position: absolute;
      transform: rotate(90deg);
      top: 35%;
      right: -5vw;
      font-size: 2.5vw;
      font-style: italic;
      z-index: 2;
      pointer-events: none; }
    .heightSize {
      position: absolute;
      font-size: 2.5vw;
      font-style: italic;
      text-align: center;
      margin-left: 22vw;
      top: 0vw;
      z-index: 2;
      pointer-events: none; }
    div {
      width: 100% !important;
      height: 100% !important;
      position: absolute; }
    .myCanvas {
      width: 50vw !important;
      height: 50vw !important;
      margin: auto;
      display: block; }
    canvas {
      height: 100% !important;
      width: 100% !important; }
    .loader {
      width: 5vw !important;
      height: 5vw !important;
      position: initial; }
    .loader-container {
      .background {
        width: 153% !important;
        height: 104% !important;
        position: absolute;
        margin-left: -9%; } }
    .refresh {
      display: none; } } }

@media only screen and (max-width : 599px) {
  #font-picker {
    margin: auto;
    width: 65vw;
    font-size: 5vw;
    margin-top: 10vw;
    margin-bottom: 12vw; }
  .textarea {
    margin: auto;
    width: 65vw;
    height: 25vw;
    font-size: 5vw;
    margin-top: 1vw;
    resize: none; }
  .App, #root {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    overflow: hidden; }

  .modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 11;
    .content {
      position: relative;
      background-color: #fff;
      width: 80vw;
      margin: auto;
      min-height: 200px;
      top: 40%;
      transform: translateY(-40%);
      border-radius: 20px; }
    .cross {
      position: absolute;
      top: 20px;
      right: 20px;
      fill: #5B40D7; }
    .done {
      position: absolute;
      margin: auto;
      width: 60vw;
      height: 12vw;
      border: none;
      background-color: #5B40D7;
      color: #fff;
      font-size: 4vw;
      font-weight: bold;
      letter-spacing: .5vw;
      border-radius: 100vw;
      bottom: 5vw;
      left: 10vw;
      &:hover {
        background-color: #3a288e; } }
    h4 {
      color: #868E96;
      text-align: center;
      font-size: 7vw;
      letter-spacing: 1.4vw;
      margin-top: 1vw;
      margin-bottom: 5vw; }
    .label {
      width: 100%;
      padding-top: 10vw;
      display: flex;
      flex-direction: column;
      padding-bottom: 25vw;
      input {
        display: none; }
      p {
        text-align: center;
        width: 30vw;
        margin-right: 15vw; }
      img {
        width: 14vw;
        height: 14vw;
        margin-left: 15vw; }
      label {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 15vw;
        text-align: center; } }
    .input {
      width: 100%;
      padding-top: 15vw;
      display: flex;
      flex-direction: column;
      padding-bottom: 25vw;
      input {
        width: 60vw;
        height: 10vw;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 5px;
        font-size: 6vw;
        text-align: center; }
      .change {
        display: flex;
        margin: auto;
        justify-content: space-around;
        width: 80%;
        margin-top: 6vw; }
      svg {
        width: 15vw;
        height: 15vw; }
      .css-tlfecz-indicatorContainer {
        svg {
          width:  4vw;
          height: 4vw; } }
      .css-1gtu0rj-indicatorContainer {
        svg {
          width: 4vw;
          height: 4vw; } }
      .add-text {
        width: 40%;
        margin: auto;
        margin-bottom: 15px;
        border: 3px solid gray;
        background: none;
        border-radius: 25px;
        height: 35px; }
      .css-1s2u09g-control {
        width: 80%;
        margin: auto; }
      .css-1pahdxg-control {
        margin: auto;
        width: 80%; }
      .css-319lph-ValueContainer {
        height: 40px; }
      .css-7pwich-Input {
        height: 30px; }
      .css-b62m3t-container {
        margin-top: 15px;
        margin-bottom: 15px; } }
    .react-colorful {
      height: 150px;
      margin: auto; }
    .colorShow {
      width: 40vw;
      height: 15vw;
      border-radius: 10px;
      border: 5px solid black;
      margin: auto;
      margin-top: 5vw; } }

  .materials {
    button {
      width: 100%;
      border: 2px #C6B9F1 solid;
      background: none;
      font-size: 4vw;
      margin-top: 2vw;
      padding: 3vw 0;
      span {
        font-weight: bold; }
      svg {
        position: absolute;
        right: 4vw;
        width: 5vw;
        height: 5vw;
        transition: 0.2s; } }
    .menuMaterials {
      z-index: 9;
      position: absolute;
      width: 100%;
      overflow: hidden;
      display: none;
      .content {
        z-index: 11;
        display: flex;
        background-color: #fff5ff;
        flex-direction: column;
        width: 100%;
        text-align: center;
        border: 2px #C6B9F1 solid;
        position: absolute;
        transition: 0.5s; }
      label {
        pointer-events: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 10vw;
        padding: 3vw 0; }
      img {
        width: 10vw;
        height: 10vw;
        margin-left: 20vw; }
      p {
        margin-right: 20vw; } } }

  .editor {
    position: relative;
    bottom: 0;
    height: 100%;
    .widthSize {
      z-index: 10;
      position: absolute;
      transform: rotate(90deg);
      top: 35%;
      right: -4vw;
      font-size: 7vw;
      font-style: italic;
      pointer-events: none; }
    .heightSize {
      z-index: 10;
      font-size: 7vw;
      font-style: italic;
      text-align: center;
      pointer-events: none; }
    label {
      font-size: 5vw;
      position: absolute;
      border: #5B40D7 solid 3px;
      padding: 1vw 3vw 1.5vw 10vw;
      border-radius: 100px;
      background-color: #EEE8FB;
      right: 2vw;
      bottom: 5vw;
      svg {
        position: absolute;
        width: 5vw;
        height: 5vw;
        left: 3vw;
        top: 1.9vw; } }
    input {
      position: absolute;
      right: -8vw;
      bottom: 5vw;
      opacity: 0; }
    div {
      width: 100% !important;
      height: 100% !important;
      top: 0;
      position: absolute; }
    .myCanvas {
      width: 90vw !important;
      height: 90vw !important;
      margin: auto;
      margin-top: 10%;
      display: block;
      margin-left: 5vw;
      position: inherit; }
    .loader {
      width: 10vw !important;
      height: 10vw !important;
      position: initial; }
    canvas {
      height: 100% !important;
      width: 100% !important; }
    .refresh {
      position: absolute;
      bottom: 4vw;
      left: 5vw;
      width: 12vw;
      height: 12vw;
      font-size: 10vw;
      padding: 0;
      margin: 0;
      border: #5B40D7 solid 3px;
      border-radius: 10px; } }



  .options {
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    button {
      margin: 0;
      padding: 0;
      height: 25vw;
      border: none;
      border-top: 3px lightgrey solid;
      background-color: #fff; }
    h5 {
      color: #868E96;
      font-size: 4.5vw;
      margin: 0;
      padding: 0;
      text-align: left;
      padding-left: 2vw; }
    p {
      margin: 0;
      padding: 0;
      text-align: left;
      padding-left: 3vw;
      margin-top: 4vw;
      font-style: italic;
      font-size: 3.5vw; }
    .form {
      position: relative;
      width: 22vw;
      &::after {
        content: '';
        position: absolute;
        width: 3px;
        height: 20vw;
        background-color: lightgrey;
        top: 0;
        left: 21.5vw; }
      h5 {
        margin-top: -3vw; }
      p {
        position: absolute;
        font-size: 3vw;
        left: -2.5vw;
        bottom: 3vw;
        text-align: center;
        width: 20vw; }
      img {
        margin-top: 0vw;
        height: 8vw;
        width: 8vw; } }

    .height {
      width: 22vw;
      h5 {
        margin-top: -3vw; }
      p {
        font-size: 4vw; }
      &::after {
        content: '';
        position: absolute;
        width: 3px;
        height: 15vw;
        background-color: lightgrey;
        top: 0;
        left: 43.5vw; } }

    .width {
      width: 22vw;
      h5 {
        margin-top: -3vw; }
      p {
        font-size: 4vw; }
      &::after {
        content: '';
        position: absolute;
        width: 3px;
        height: 20vw;
        background-color: lightgrey;
        top: 0;
        left: 65.5vw; } }

    .quantity {
      width: 34vw;
      div {
        display: flex; }
      p {
        border: 2px black solid;
        padding: 1.4vw 2vw .7vw 7vw;
        font-size: 4vw;
        margin-left: 1vw;
        margin-right: 1vw; }
      .iconQty {
        margin-top: 4vw;
        margin-left: 1vw;
        width: 8vw;
        height: 8vw; } }

    .text {
      width: 20vw;
      h5 {
        margin-top: 0vw; }
      svg {
        width: 12vw;
        height: 12vw; }
      &::after {
        content: '';
        position: absolute;
        width: 3px;
        height: 15vw;
        background-color: lightgrey;
        top: 25vw;
        left: 19.5vw; } }
    .background {
      opacity: 1;
      position: initial;
      width: 27vw;
      h5 {
        margin-top: -2vw; }
      .myColor {
        width: 7vw;
        height: 7vw;
        background-color: white;
        border: 1px lightgray solid;
        border-radius: 100%;
        -webkit-box-shadow: 5px 5px 10px -2px rgba(0,0,0,0.5);
        box-shadow: 5px 5px 10px -2px rgba(0,0,0,0.5);
        margin-top: 3vw;
        margin-left: 8vw; }
      &::after {
        content: '';
        position: absolute;
        width: 3px;
        height: 15vw;
        background-color: lightgrey;
        top: 25vw;
        left: 46.5vw; } }
    .image {
      width: 22vw;
      svg {
        margin-top: 3vw;
        width: 8vw;
        height: 8vw; } }
    .basket {
      display: flex;
      width: 31vw;
      border: 3px solid lightgray;
      border-bottom: none;
      border-right: none;
      background-color: #F2A93B;
      color: #fff;
      h5 {
        color: #fff;
        font-size: 3.8vw;
        margin-top: 6vw;
        line-height: 7vw; }
      svg {
        margin-top: 8vw;
        margin-left: 1vw;
        width: 10vw;
        height: 10vw; } } } }
